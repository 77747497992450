import React, { useMemo } from 'react';
import Link from 'components/shared/Link';
import { NavigationItem } from 'components/navigation/utils/libs';
import { getNavigationItemUrl } from 'components/navigation/verticalNavigation/mainMenu/MenuItems';
import { Icon, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { useVerticalNavigation } from '../provider/VerticalNavigationProvider';
import useBoolean from 'utils/hooks/useBoolean';

type Props = {
  item: NavigationItem;
  isActive?: boolean;
};

const LinkComponent: React.FC<Props> = React.memo(({ item, isActive }) => {
  const [isHovered, setIsHovered] = useBoolean(false);
  const { onMouseEnter } = useVerticalNavigation();

  return useMemo(() => {
    return (
      <Link
        onClick={(e) => {
          if (!(item.url || item.routerUrl)) {
            e.preventDefault();
          }
        }}
        to={getNavigationItemUrl(item)}
        isRouterLink={!!item.routerUrl}
        data-name={`navigation-link-${item.key}`}
        data-cy={`navigation-link-${item.key}`}
        onMouseEnter={() => {
          setIsHovered.on();
          if (item.key && !item.isPageSubmenu) {
            onMouseEnter(item);
          }
        }}
        onMouseLeave={() => {
          setIsHovered.off();
        }}
      >
        <div>
          <VerticalMenuItemWrapper
            isActiveMenu={isActive}
            className=" hover:tw-text-primary-100"
          >
            <span
              className={clsx(
                'tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-md',
                {
                  'tw-bg-primary-10': isHovered,
                },
              )}
            >
              {item.icon && <Icon icon={item.icon} size="lg" />}
            </span>
          </VerticalMenuItemWrapper>
        </div>
      </Link>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isHovered]);
});

const MenuLink: React.FC<Props> = ({ item, isActive }) => {
  if (item.items) {
    return <LinkComponent item={item} isActive={isActive} />;
  }

  return (
    <Tooltip
      placement="right"
      content={item.label}
      arrow={false}
      className="tw-shadow-md"
    >
      <LinkComponent item={item} isActive={isActive} />
    </Tooltip>
  );
};

export default React.memo(MenuLink, (prevProps, nextProps) => {
  return (
    prevProps.item.key === nextProps.item.key &&
    prevProps.isActive === nextProps.isActive
  );
});
