import { CharacterCount } from '@tiptap/extension-character-count';
import { Mention } from '@tiptap/extension-mention';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { Page } from 'types/Page';
import { Command, CommandProps, Extension, ReactRenderer } from '@tiptap/react';
import tippy, { GetReferenceClientRect, Instance } from 'tippy.js';
import { PluginKey } from 'prosemirror-state';
import { HardBreak } from '@tiptap/extension-hard-break';
import { MentionableUser, User } from 'types/User';
import StringUtils from 'utils/string';
import { TextEditorFeature } from 'app/modules/textEditor/constants/textEditorFeature';
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import twitterText from 'twitter-text';
import { UseTextEditorProps } from 'app/modules/textEditor/hooks/useTextEditor';
import Placeholder from '@tiptap/extension-placeholder';
import Document from '@tiptap/extension-document';
import { Paragraph } from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import Link from '@tiptap/extension-link';
import ArrayUtils from 'app/utils/array';
import SuggestionsQueryData from 'app/modules/textEditor/components/SuggestionsQueryData';
import React from 'react';
import { SuggestionsList } from 'app/modules/textEditor/components/SuggestionsList';
import { FileAttachment } from '../hooks/useFileAttachments';

declare module '@tiptap/extension-character-count' {
  export interface CharacterCountStorage {
    specials(): {
      mentions: number;
      hashtags: number;
    };
  }
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customExtension: {
      insertUniqueUserMention: (
        user: Pick<User, 'id' | 'name' | 'surname' | 'role'>,
        options?: {
          position?: number;
        },
      ) => ReturnType;
      removeMentions: (userIds: number[]) => ReturnType;
    };
  }
}

type Props = Pick<
  UseTextEditorProps,
  'valueType' | 'placeholder' | 'features' | 'page' | 'mentionableUsers'
> & {
  setRefineTextSelectionVisible(value: boolean): void;
};

export function getTextEditorExtensions({
  valueType,
  placeholder,
  features,
  page,
  mentionableUsers,
  setRefineTextSelectionVisible,
}: Props) {
  // order matters
  const extensions = [];

  if (valueType === 'raw') {
    extensions.push(ExtendedHardBreak);
  }

  extensions.push(
    Placeholder.configure({ placeholder }),
    Document,
    Paragraph,
    Text,
    Link.configure({ openOnClick: false }),
  );

  if (features.includes(TextEditorFeature.UndoRedo)) {
    extensions.push(History);
  }

  if (features.includes(TextEditorFeature.CharacterCounter)) {
    extensions.push(ExtendedCharacterCounter);
  }

  if (page) {
    if (
      features.includes(TextEditorFeature.PageMention) &&
      (
        [
          TYPE.FACEBOOK,
          TYPE.FACEBOOK_AD,
          TYPE.LINKEDIN,
          TYPE.TWITTER,
        ] as number[]
      ).includes(page.type)
    ) {
      extensions.push(PageMention(page));
    }

    if (
      features.includes(TextEditorFeature.HashtagMention) &&
      ([TYPE.TWITTER] as number[]).includes(page.type)
    ) {
      extensions.push(HashtagMention(page));
    }
  } else if (
    mentionableUsers &&
    features.includes(TextEditorFeature.UserMention)
  ) {
    extensions.push(UserMention());
  }

  if (features.includes(TextEditorFeature.FileAttachments)) {
    extensions.push(FileAttachmentsExtensions());
  }

  if (features.includes(TextEditorFeature.AIContent)) {
    extensions.push(
      AIContentExtension({
        setRefineTextSelectionVisible,
      }),
    );
  }

  return extensions;
}

const ExtendedCharacterCounter = CharacterCount.extend({
  onBeforeCreate() {
    /// must be inspired by original at https://github.com/ueberdosis/tiptap/blob/main/packages/extension-character-count/src/character-count.ts#L50
    this.storage.characters = (options) => {
      const node = options?.node || this.editor.state.doc;
      const mode = options?.mode || this.options.mode;

      if (mode === 'textSize') {
        const text = node.textBetween(0, node.content.size, undefined, ' ');

        if (this.editor.storage.props?.page?.type === TYPE.TWITTER) {
          return twitterText.getTweetLength(text);
        }

        return text.length;
      }

      return node.nodeSize;
    };

    this.storage.words = (options) => {
      const node = options?.node || this.editor.state.doc;
      const text = node.textBetween(0, node.content.size, ' ', ' ');
      const words = text.split(' ').filter((word) => word !== '');

      return words.length;
    };

    // added functionality
    this.storage.specials = () => {
      const text = this.editor.getText();

      return {
        hashtags: text.split('#').length - 1,
        mentions: text.split('@').length - 1,
      };
    };
  },
});

const PageMention = (page: Pick<Page, 'id' | 'type'>) => {
  return Mention.extend({
    renderText({ node }) {
      switch (page.type) {
        case TYPE.FACEBOOK:
          return `@[${node.attrs.label}](page:${node.attrs.id})`;
        case TYPE.LINKEDIN:
          return `@[${node.attrs.label}](company:${node.attrs.id})`;
        default:
          return `@${node.attrs.label}`;
      }
    },
  }).configure({
    HTMLAttributes: { class: 'tw-bg-primary-10' },
    suggestion: {
      // TODO allow space bugfix https://github.com/ueberdosis/tiptap/issues/214#issuecomment-964557956
      // allowSpaces: true,
      render: () =>
        suggestionsRenderer({
          pageType: page.type,
          feature: TextEditorFeature.PageMention,
        }),
    },
  });
};

const HashtagMention = (page: Pick<Page, 'id' | 'type'>) => {
  return Mention.extend({
    name: 'hashtag-mention',
    renderText({ node }) {
      return `#${node.attrs.label}`;
    },
  }).configure({
    HTMLAttributes: { class: 'tw-bg-primary-10' },
    suggestion: {
      pluginKey: new PluginKey('hashtag-mention'),
      char: '#',
      render: () =>
        suggestionsRenderer({
          pageType: page.type,
          feature: TextEditorFeature.HashtagMention,
        }),
    },
  });
};

const UserMention = () => {
  return Mention.extend({
    addCommands() {
      return {
        insertUniqueUserMention:
          (
            user: Pick<User, 'id' | 'name' | 'surname' | 'role'>,
            options?: { position?: number },
          ): Command =>
          ({ chain, editor }: CommandProps) => {
            const editorContent = editor.state.doc;
            let alreadyExists = false;

            const getUserFullName = (): string => {
              const { name, surname } = user;
              return surname ? `${name} ${surname}` : name;
            };

            editorContent.descendants((node) => {
              if (
                node.type.name === this.name &&
                node.attrs.id === user.id &&
                node.attrs.label === getUserFullName()
              ) {
                alreadyExists = true;
              }
            });

            if (alreadyExists) {
              return true;
            }

            const currentCursorPosition = editor.state.selection.head;
            const position = options?.position ?? currentCursorPosition;

            const mentionNode = {
              type: this.name,
              attrs: {
                id: user.id,
                label: getUserFullName(),
                role: user.role,
              },
            };
            const textNode = { type: 'text', text: ' ' };

            return chain()
              .insertContentAt(position, [mentionNode, textNode])
              .run();
          },

        removeMentions:
          (userIds: number[]): Command =>
          ({ state, dispatch }: CommandProps) => {
            if (!dispatch) {
              return false;
            }

            const processMentionsToRemove = (): {
              from: number;
              to: number;
            }[] => {
              const positions: { from: number; to: number }[] = [];
              const mentionType = state.schema.nodes.mention;

              state.doc.descendants((node, pos) => {
                if (
                  node.type === mentionType &&
                  userIds.includes(node.attrs.id)
                ) {
                  const endPosition = pos + node.nodeSize;
                  let deleteRangeEnd = endPosition;

                  const isNextCharacterSpace = state.doc.textBetween(
                    endPosition,
                    endPosition + 1,
                    undefined,
                    '\0',
                  );
                  if (isNextCharacterSpace === ' ') {
                    deleteRangeEnd = endPosition + 1;
                  }
                  positions.push({ from: pos, to: deleteRangeEnd });
                }
              });

              return positions;
            };

            const positions = processMentionsToRemove();

            if (positions.length === 0) {
              return false;
            }

            let transaction = state.tr;

            positions.reverse().forEach(({ from, to }) => {
              transaction = transaction.delete(from, to);
            });

            dispatch(transaction);
            return true;
          },
      };
    },
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-id'),
          renderHTML: (attributes) => {
            if (!attributes.id) return {};

            return { 'data-id': attributes.id };
          },
        },

        label: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-label'),
          renderHTML: (attributes) => {
            if (!attributes.label) return {};

            return { 'data-label': attributes.label };
          },
        },

        role: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-role'),
          renderHTML: (attributes) => {
            if (!attributes.role) return {};

            return { 'data-role': attributes.role };
          },
        },
      };
    },

    renderText({ node }) {
      return `@[${node.attrs.id}]`;
    },
  }).configure({
    HTMLAttributes: {
      class:
        "mention tw-text-primary-100 tw-bg-primary-10 data-[role='client']:!tw-text-purple-100 data-[role='client']:!tw-bg-purple-10 tw-rounded-sm tw-px-1",
    },
    suggestion: {
      // TODO allow space bugfix https://github.com/ueberdosis/tiptap/issues/214#issuecomment-964557956
      // allowSpaces: true,
      items: async ({ query, editor }) => {
        const mentionableUsers = editor.storage.props
          .mentionableUsers as MentionableUser[];

        query = query.replaceAll('_', ' ');

        return ArrayUtils.sortByKey(
          mentionableUsers
            .map((user) => ({
              ...user,
              image: user.avatar?.src,
              name: `${user.name} ${user.last_name ?? ''}`,
              role: user.role,
              isDemo: user.is_demo,
            }))
            .filter((user) => StringUtils.isSubstring(query, user.name)),
          'name',
        );
      },

      render: suggestionsRenderer,
    },
  });
};

const AsyncSuggestionsList = React.forwardRef<
  any,
  SuggestionProps & {
    config: { pageType: number; feature: TextEditorFeature };
  }
>((props, ref) => (
  <SuggestionsQueryData {...props.config} query={props.query}>
    {(data) => <SuggestionsList {...props} items={data} ref={ref} />}
  </SuggestionsQueryData>
));

type ExtractReturnType<T> = T extends () => infer R ? R : never;
type SuggestionRender = (config?: {
  pageType: number;
  feature: TextEditorFeature;
}) => ExtractReturnType<SuggestionOptions['render']>;

const suggestionsRenderer: SuggestionRender = (config?: {
  pageType: number;
  feature: TextEditorFeature;
}) => {
  let reactRenderer: (ReactRenderer & { ref: any }) | undefined;
  let popup: Instance[] | undefined;

  return {
    onStart: (props) => {
      reactRenderer = new ReactRenderer(
        // @ts-ignore
        config ? AsyncSuggestionsList : SuggestionsList,
        {
          props: {
            ...props,
            config,
          },
          editor: props.editor,
        },
      );

      if (!props.clientRect) {
        return;
      }

      popup = tippy('body', {
        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        appendTo: () => document.body,
        content: reactRenderer.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        maxWidth: '500px',
        arrow: false,
        theme: 'suggestion-list',
        placement: 'auto',
      });
    },
    onUpdate(props) {
      reactRenderer?.updateProps(props);

      if (!props.clientRect) {
        return;
      }

      popup?.forEach((tippy) => {
        tippy.setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      });
    },
    onKeyDown(props) {
      if (props.event.key === 'Escape') {
        popup?.forEach((tippy) => tippy.hide());
        props.event.stopPropagation();

        return true;
      }

      return reactRenderer?.ref?.onKeyDown(props);
    },

    onExit() {
      popup?.forEach((tippy) => tippy.destroy());
      reactRenderer?.destroy();
    },
  };
};

const ExtendedHardBreak = HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.setHardBreak(),
    };
  },
});

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fileAttachments: {
      // @override
      clearContent: () => ReturnType;
    };
  }
}

export type FileAttachmentsExtensionStorage = {
  getFiles: () => FileAttachment[];
  clearFiles: () => void;
  setFiles: (attachments: FileAttachment[]) => void;
};

const FileAttachmentsExtensions = () => {
  return Extension.create<{}, FileAttachmentsExtensionStorage>({
    name: TextEditorFeature.FileAttachments,
    addStorage() {
      return {
        setFiles: () => {},
        getFiles: () => [],
        clearFiles: () => {},
      };
    },
    addCommands() {
      return {
        // @override
        clearContent:
          () =>
          ({ chain }) => {
            this.storage.clearFiles();
            chain().setContent('').run();

            return false;
          },
      };
    },
  });
};

type AiContentExtensionStorage = {
  lastSelectionIsEmpty: boolean;
  lastSelectionText: string;
  lastSelection: undefined | Selection;
};

const AIContentExtension = ({
  setRefineTextSelectionVisible,
}: {
  setRefineTextSelectionVisible(value: boolean): void;
}) => {
  return Extension.create<{}, AiContentExtensionStorage>({
    name: TextEditorFeature.AIContent,
    addStorage() {
      return {
        lastSelectionIsEmpty: true,
        lastSelectionText: '',
        lastSelection: undefined,
      };
    },
    onSelectionUpdate() {
      const { editor } = this;

      const {
        empty: lastSelectionIsEmpty,
        from: selectionFrom,
        to: selectionTo,
      } = editor.state.selection;

      const prevIsVisible = !!(
        !editor.extensionStorage.aiContent.lastSelectionIsEmpty &&
        editor.extensionStorage.aiContent.lastSelectionText
      );

      const newlastSelectionIsEmpty = lastSelectionIsEmpty;
      const newlastSelectionText = editor.state.doc.textBetween(
        selectionFrom,
        selectionTo,
        ' ',
      );

      const newIsVisible = !!(!newlastSelectionIsEmpty && newlastSelectionText);

      if (prevIsVisible !== newIsVisible) {
        setRefineTextSelectionVisible(newIsVisible);
      }

      editor.extensionStorage.aiContent.lastSelection = editor.state.selection;
      editor.extensionStorage.aiContent.lastSelectionText =
        newlastSelectionText;
      editor.extensionStorage.aiContent.lastSelectionIsEmpty =
        newlastSelectionIsEmpty;
    },
  });
};
