import { FC, useState } from 'react';
import DatePicker from 'components/shared/DatePicker';
import { useInsightsContainer } from 'components/insights/context';
import { DATE_PRESETS } from '@kontentino/kontentino-constants/Presets';
import PeriodToCompareDatePicker from './datesPickers/PeriodToCompareDatePicker';
import { useLocation } from 'react-router-dom';
import routes from 'constants/routes';
import { Button, Icon } from '@kontentino/ui';
import { faArrowsCross } from '@fortawesome/pro-regular-svg-icons/faArrowsCross';
import dayjs from 'dayjs';
type Props = {};

const DatesPickers: FC<Props> = () => {
  const { filterState, changeDateFilter, clearComparedDateFilter } =
    useInsightsContainer();
  const [isComparing, setIsComparing] = useState<boolean>(
    !!filterState.comparedStartDate && !!filterState.comparedEndDate,
  );

  const location = useLocation();

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      <DatePicker
        className="tw-h-[36px] tw-max-w-[210px]"
        shouldSubmitOnRangeSelect={false}
        shouldPreselect={false}
        startDate={filterState.startDate}
        endDate={filterState.endDate}
        onDateChange={changeDateFilter}
        withPresets
        presets={Object.keys(DATE_PRESETS.PRESETS)}
        maxEndDate={dayjs()}
        placeholderDataName="insights_date-range_button"
        dataName={{
          presetsList: 'insights_date-range_quickSelectDate',
          dateRangePicker: 'insights_date-range_dayPicker',
          footer: {
            cancelButton: 'insights_date-range_cancel',
            submitButton: 'insights_date-range_submit',
          },
        }}
      />
      {location.pathname.includes(routes.INSIGHTS_PAGE_PERFORMANCE) && (
        <>
          <Button
            iconBefore={<Icon icon={faArrowsCross} />}
            variant={isComparing ? 'primary' : 'secondary'}
            className="tw-h-[36px]"
            onClick={() => setIsComparing(!isComparing)}
            data-name={`insights_page-performance_compare${
              isComparing ? '-remove' : ''
            }`}
          >
            {!isComparing && <span>Compare</span>}
          </Button>
          {isComparing && (
            <PeriodToCompareDatePicker
              filterState={filterState}
              changeDateFilter={changeDateFilter}
              clearComparedDateFilter={clearComparedDateFilter}
            />
          )}
        </>
      )}
    </div>
  );
};
export default DatesPickers;
