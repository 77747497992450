// https://docs.google.com/spreadsheets/d/1s9NPKtmSzgao-DMvz6hEhdAOUyon4xwLzjxTCjDFIvo/edit#gid=202457332

export enum UserPermission {
  // Post
  POST_CREATE = 'post_create',
  POST_DELETE = 'post_delete',
  POST_EDIT = 'post_edit',
  POST_EDIT_TEXT = 'post_edit_text',
  POST_EDIT_TRANSLATION = 'post_edit_translation',
  POST_EDIT_TAGS = 'post_edit_tags',
  POST_EDIT_DATE_TIME = 'post_edit_date_time',
  POST_EDIT_NOTE = 'post_edit_note',
  POST_SHOW_NOTE_IN_DETAIL = 'post_show_note_in_detail',
  POST_EDIT_PROJECT = 'post_edit_project',
  POST_EDIT_ASSETS = 'post_edit_assets',
  POST_EDIT_BUDGET = 'post_edit_budget',
  POST_SCHEDULE = 'post_schedule',
  POST_INTERNAL_APPROVE = 'post_internal_approve',
  POST_CLIENT_APPROVE = 'post_client_approve',
  POST_SEND_FOR_INTERNAL_APPROVAL = 'post_send_for_internal_approval',
  POST_SEND_FOR_CLIENT_APPROVAL = 'post_send_for_client_approval',
  POST_DUPLICATE = 'post_duplicate',
  POST_MOVE = 'post_move',
  POST_EDIT_STATUS = 'post_edit_status',
  POST_SHOW_REQUIREMENTS = 'post_show_requirements',
  POST_CREATE_REQUIREMENTS = 'post_create_requirements',
  POST_TOGGLE_REQUIREMENTS = 'post_toggle_requirements',
  POST_COMMUNICATION_CLIENT_CREATE = 'post_communication_client_create',
  POST_COMMUNICATION_CLIENT_SHOW = 'post_communication_client_show',
  POST_COMMUNICATION_INTERNAL_CREATE = 'post_communication_internal_create',
  POST_COMMUNICATION_INTERNAL_SHOW = 'post_communication_internal_show',
  POST_COMMUNICATION_DELETE = 'post_communication_delete',
  POST_HASHTAG_PRESETS = 'post_hashtag_presets',
  POST_MASS_CHANGE_POST_STATUS = 'post_mass_change_post_status',
  POST_SHARE_PREVIEW = 'post_share_preview',
  POST_MASS_EXPORT = 'post_mass_export',
  POST_MASS_EXPORT_MULTIPREVIEW = 'post_mass_export_multipreview',
  POST_DISTRIBUTE_GLOBAL_CHANGES = 'post_distribute_global_changes',
  POST_APPLY_GLOBAL_CHANGES = 'post_apply_global_changes',
  POST_MARK_AS_DONE = 'post_mark_as_done',
  POST_SEE_EXTERNAl = 'post_see_external',
  POST_COPY_LINK = 'post_copy_link',

  //Post Statuses
  POST_LIST_ALL = 'post_list_all',
  POST_LIST_WAITING_FOR_TASK = 'post_list_waiting_for_task',
  POST_LIST_TASK_DONE = 'post_list_done',
  POST_LIST_WAITING_FOR_APPROVAL = 'post_list_waiting_for_approval',
  POST_LIST_INTERNALLY_APPROVED = 'post_list_internally_approved',
  POST_LIST_AT_CLIENT = 'post_list_at_client',
  POST_LIST_APPROVED_BY_CLIENT = 'post_list_approved_by_client',
  POST_LIST_POSTED = 'post_list_posted',
  POST_LIST_INTERNALLY_REJECTED = 'post_list_internally_rejected',
  POST_LIST_REJECTED_BY_CLIENT = 'post_list_rejected_by_client',
  POST_LIST_COMPLETED = 'post_list_completed',
  POST_LIST_DRAFT = 'post_list_draft',
  POST_LIST_EXPORT_FAILED = 'post_list_export_failed',
  POST_LIST_IS_SCHEDULING = 'post_list_is_scheduling',
  POST_LIST_SCHEDULED = 'post_list_scheduled',

  // Ad Accounts
  AD_ACCOUNT_MANAGE = 'ad_account_manage',

  // Ads
  // TODO: replace permissions with ads permissions once it is ready
  AD_CREATE = 'post_create',
  AD_EDIT = 'post_edit',
  AD_DELETE = 'post_delete',
  AD_SHOW_REQUIREMENTS = 'post_show_requirements',
  AD_EDIT_NOTE = 'post_edit_note',
  AD_DUPLICATE = 'post_duplicate',

  // Campaigns
  CAMPAIGN_MANAGE = 'campaign_manage',

  //Ad Sets
  AD_SET_MANAGE = 'ad_set_manage',

  // Pages
  PAGE_CREATE = 'page_create',
  PAGE_EDIT = 'page_edit',
  PAGE_DELETE = 'page_delete',
  PAGE_MANAGE_ALL = 'page_manage_all',
  PAGE_RESTORE = 'page_restore',

  // Projects - content plans
  PROJECT_SHOW = 'project_show',
  PROJECT_CREATE = 'project_create',
  PROJECT_EDIT = 'project_edit',
  PROJECT_DELETE = 'project_delete',
  PROJECT_EXPORT_CONTENT = 'project_export_content',
  PROJECT_EXPORT_ASSETS = 'project_export_assets',

  // Profile groups - presets
  PAGE_PRESETS_MANAGE = 'page_presets_manage',
  PAGE_PRESETS_SHARE = 'page_presets_share',

  // Requirements
  REQUIREMENT_CREATE = 'requirement_create',
  REQUIREMENT_EDIT = 'requirement_edit',
  REQUIREMENT_DELETE = 'requirement_delete',
  REQUIREMENT_LIST = 'requirement_list',
  REQUIREMENT_MANAGE_GLOBAL = 'requirement_manage_global',

  // Inspirations
  INSPIRATION_CREATE = 'inspiration_create',
  INSPIRATION_EDIT = 'inspiration_edit',
  INSPIRATION_DELETE = 'inspiration_delete',
  INSPIRATION_CREATE_FROM_CALENDAR = 'inspiration_create_from_calendar',
  INSPIRATION_SHOW_IN_CALENDAR = 'inspiration_show_in_calendar',
  INSPIRATION_SHOW_GROUPS = 'inspiration_show_groups',
  INSPIRATION_MANAGE_ALL = 'inspiration_manage_all',

  // Engage
  ENGAGE_MANAGE_REPLIES = 'engage_manage_replies',

  // Insights
  INSIGHT_EXPORT = 'insight_export',

  // Reports
  REPORT_CREATE = 'report_create',
  REPORT_EXPORT = 'report_export',
  REPORT_EDIT = 'report_edit',
  REPORT_DELETE = 'report_delete',

  // Media library
  MEDIA_LIBRARY_MANAGE_MEDIA = 'media_library_manage_media',

  // Billing
  BILLING_MANAGE_SUBSCRIPTION = 'billing_manage_subscription',

  // Menu - accesses
  ACCESS_CALENDAR = 'access_calendar',
  ACCESS_INSPIRATIONS = 'access_inspirations',
  ACCESS_ENGAGE = 'access_engage',
  ACCESS_ADS_PLANNER = 'access_ads_planner',
  ACCESS_ANALYTICS = 'access_analytics',
  ACCESS_REPORTS = 'access_reports',
  ACCESS_SOCIAL_LOGIN = 'access_social_login',
  ACCESS_USERS = 'access_users',
  ACCESS_BILLING = 'access_billing',
  ACCESS_COMPANY_SETTINGS = 'access_company_settings',
  ACCESS_PROFILES = 'access_profiles',
  ACCESS_WORKFLOW = 'access_workflow',
  ACCESS_MEDIA_LIBRARY = 'access_media_library',
  ACCESS_PROFILE_GROUPS = 'access_profile_groups',
  ACCESS_MY_TASKS = 'access_my_tasks',
  ACCESS_DASHBOARD = 'access_dashboard',
  ACCESS_BULK_ACTIONS = 'access_bulk_actions',
  ACCESS_GLOBAL_PAGES = 'access_global_pages',

  // User Permissions
  USER_PRIVILEGED = 'user_privileged',
  USER_MANAGE_ALL = 'user_manage_all',
  USER_CREATE = 'user_create',
  USER_EDIT = 'user_edit',
  USER_DELETE = 'user_delete',
  USER_RESTORE = 'user_restore',
  USER_CHANGE_ROLE = 'user_change_role',
  USER_ASSIGN_PAGE = 'user_assign_page',
  USER_ASSIGN_TASK = 'user_assign_task',
  TASK_ASSIGNABLE = 'task_assignable',

  // Company Settings
  COMPANY_SETTINGS_MANAGE = 'company_settings_manage',

  // Hashtags Presets
  HASHTAG_PRESETS_MANAGE = 'hashtag_presets_manage',
  HASHTAG_PRESETS_MANAGE_ALL = 'hashtag_presets_manage_all',

  // Tags - labels
  TAG_CREATE = 'tag_create',
  TAG_EDIT = 'tag_edit',
  TAG_DELETE = 'tag_delete',
  TAG_LIST = 'tag_list',

  // Image templates
  IMAGE_TEMPLATE_CREATE = 'image_template_create',
  IMAGE_TEMPLATE_EDIT = 'image_template_edit',
  IMAGE_TEMPLATE_DELETE = 'image_template_delete',

  // Audience restrictions
  AUDIENCE_RESTRICTIONS_MANAGE = 'audience_restrictions_manage',
  AUDIENCE_RESTRICTIONS_PRESET_ASSIGN = 'audience_restrictions_preset_assign',
  AUDIENCE_RESTRICTIONS_PRESET_ASSIGNABLE = 'audience_restrictions_preset_assignable',
}
