import React, { FC } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faFileChartColumn } from '@fortawesome/pro-regular-svg-icons/faFileChartColumn';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import AnalyticsExportsApi from 'api/analytics/exports';
import { useInsightsContainer } from 'components/insights/context';
import DateUtils from 'app/utils/date';

type Props = {
  page: Page;
};

const EXPORTS_OPTIONS = [
  {
    label: 'Current selection',
    selection: 'current-selection',
    options: [
      { type: 'pdf', label: 'PDF' },
      { type: 'csv', label: 'CSV' },
    ],
  },
  {
    label: 'Page overview',
    selection: 'overview',
    options: [{ type: 'pdf', label: 'PDF' }],
  },
] as const;

const PagePerformanceExportsDropdown: FC<Props> = ({ page }) => {
  const { filterState, activeState } = useInsightsContainer();
  const { t } = useTranslation();

  const { mutate: mutatePDFExport } = useMutation<
    Window | null,
    null,
    {
      selection: 'overview' | 'current-selection';
    }
  >((variables) => {
    if (activeState.page) {
      return AnalyticsExportsApi.exportPDFPagePerformance(
        {
          startDate: DateUtils.toDateString(filterState.startDate),
          endDate: DateUtils.toDateString(filterState.endDate),
          types: filterState.selectedPostTypes,
          metric: activeState.metric,
          page: activeState.page,
          comparedStartDate: filterState.comparedStartDate
            ? DateUtils.toDateString(filterState.comparedStartDate)
            : undefined,
          comparedEndDate: filterState.comparedEndDate
            ? DateUtils.toDateString(filterState.comparedEndDate)
            : undefined,
        },
        {
          type: variables.selection,
        },
      );
    }

    return Promise.reject();
  });

  const { mutate: mutateCSVExport } = useMutation(() => {
    if (activeState.page) {
      return AnalyticsExportsApi.exportCSVPagePerformance({
        startDate: DateUtils.toDateString(filterState.startDate),
        endDate: DateUtils.toDateString(filterState.endDate),
        page: activeState.page,
      });
    }

    return Promise.reject();
  });

  const handleExportOptionSelection = (
    type: 'csv' | 'pdf',
    selection: 'overview' | 'current-selection',
  ) => {
    if (type === 'csv') {
      mutateCSVExport();
    } else {
      mutatePDFExport({
        selection,
      });
    }
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <IconButton
          icon={<Icon icon={faArrowDownToLine} />}
          variant="secondary"
          className="tw-h-[36px]"
          disabled={!page}
          data-name="insights-page-performance-download"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10 tw-min-w-[240px]">
        {EXPORTS_OPTIONS.map((option, index) => (
          <DropdownMenu.Sub key={index}>
            <DropdownMenu.SubTrigger>
              <DropdownMenu.Item>
                <Icon icon={faFileChartColumn} />
                {option.label}
                <Icon
                  size="sm"
                  className="tw-ml-auto tw-text-grayscale-100"
                  icon={faChevronRight}
                />
              </DropdownMenu.Item>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent>
                {option.options.map((exportOption) => (
                  <DropdownMenu.Item
                    key={exportOption.type}
                    onClick={() => {
                      handleExportOptionSelection(
                        exportOption.type,
                        option.selection,
                      );
                    }}
                    data-name="insights-page-performance-download_pdf"
                  >
                    <Icon icon={faArrowDownToLine} />
                    {t('exportAs', { type: exportOption.label })}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default PagePerformanceExportsDropdown;
