import { ROLE } from '@kontentino/kontentino-constants/User';
import {
  PostStatus,
  STATUS,
  STATUS_EXPORT_FAILED,
  STATUS_IS_SCHEDULING,
  STATUSES_VISIBLE_BY_CLIENT,
  STATUSES_VISIBLE_BY_DESIGNER,
  STATUSES_VISIBLE_BY_MANAGER,
  STATUSES_VISIBLE_BY_MEDIA_AGENCY,
  STATUSES_VISIBLE_BY_TRANSLATOR,
} from '@kontentino/kontentino-constants/Posts';
import { UserRole } from 'types/User';
import { UserPermission } from 'constants/userPermission';
import { POST_STATUS_BY_USER_PERMISSION } from 'app/modules/posts/constants';

export const getStatusesByUserRole = (userRole: UserRole): PostStatus[] => {
  function getByRole(): PostStatus[] {
    switch (userRole) {
      case ROLE.ADMIN:
      case ROLE.MANAGER:
        return STATUSES_VISIBLE_BY_MANAGER;
      case ROLE.CLIENT:
        return STATUSES_VISIBLE_BY_CLIENT;
      case ROLE.DESIGNER:
        return STATUSES_VISIBLE_BY_DESIGNER;
      case ROLE.TRANSLATOR:
        return STATUSES_VISIBLE_BY_TRANSLATOR;
      case ROLE.MEDIA_AGENCY:
        return STATUSES_VISIBLE_BY_MEDIA_AGENCY;
      default:
        return [];
    }
  }

  if (window.location.pathname.includes('adsplanner')) {
    return getByRole();
  }

  return getByRole().filter(
    (status) =>
      !([STATUS_EXPORT_FAILED, STATUS_IS_SCHEDULING] as PostStatus[]).includes(
        status,
      ),
  );
};

export const SORTED_STATUSES = [
  STATUS.DRAFT,
  STATUS.COMPLETED,
  STATUS.WAITING_FOR,
  STATUS.DONE,
  STATUS.WAITING_FOR_INTERNAL_APPROVAL,
  STATUS.REJECTED,
  STATUS.INTERNAL_APPROVED,
  STATUS.AT_CLIENT,
  STATUS.REJECTED_BY_CLIENT,
  STATUS.APPROVED,
  STATUS.IS_SCHEDULING,
  STATUS.SCHEDULED,
  STATUS.POSTED,
  STATUS.EXPORT_FAILED,
] as number[];

export const getStatusesByUserPermissions = (
  permissionsKeys: UserPermission[],
): number[] => {
  if (permissionsKeys.includes(UserPermission.POST_LIST_ALL)) {
    return Object.values(POST_STATUS_BY_USER_PERMISSION);
  }

  const statusesByPermissions = permissionsKeys
    .filter((permissionKey) => permissionKey in POST_STATUS_BY_USER_PERMISSION)
    .map((permissionKey) => POST_STATUS_BY_USER_PERMISSION[permissionKey]);

  return statusesByPermissions.sort((statusA, statusB) => {
    const indexA = SORTED_STATUSES.indexOf(statusA);
    const indexB = SORTED_STATUSES.indexOf(statusB);
    return indexA - indexB;
  });
};
