import React, { createContext, FC, useState } from 'react';
import { MetricCardData } from 'app/modules/insights/types/PagePerformance';

type PageMetricCardContentContextState = {
  activeTabMetric: string;
  setActiveTabMetric: (metric: string) => void;
  metricCardData: MetricCardData;
};

const PageMetricCardContentContext = createContext<
  undefined | PageMetricCardContentContextState
>(undefined);

type Props = {
  children: React.ReactNode;
  metricCardData: MetricCardData;
};

const PageMetricCardContentProvider: FC<Props> = ({
  children,
  metricCardData,
}) => {
  const [activeTabMetric, setActiveTabMetric] = useState<string>(
    metricCardData.metricGraphData[0].key,
  );

  return (
    <PageMetricCardContentContext.Provider
      value={{
        activeTabMetric,
        setActiveTabMetric,
        metricCardData,
      }}
    >
      {children}
    </PageMetricCardContentContext.Provider>
  );
};

export default PageMetricCardContentProvider;

export const usePageMetricCardContentContext =
  (): PageMetricCardContentContextState => {
    const context = React.useContext(PageMetricCardContentContext);

    if (context === undefined) {
      throw new Error(
        'usePageMetricCardContent must be used within a PageMetricCardContentProvider',
      );
    }

    return context;
  };
