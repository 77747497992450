import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AIContentApi } from 'app/modules/posts/api/aiContent';
import { ApiClientError } from 'api/client';
import { queryKey } from 'constants/queryKey';
import { useToast } from 'app/hooks/useToast';

type Props = {
  accountPlanType: string;
  disabled?: boolean;
};

export function useAiContentQueries({ accountPlanType, disabled }: Props) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const options = useQuery(
    queryKey.aiContentOptions(),
    () => AIContentApi.options({ plan_type: accountPlanType }),
    {
      enabled: !disabled,
    },
  );

  const mutationOptions = {
    onError(e: ApiClientError) {
      toast(e.userMessage ?? 'Something went wrong', 'error');
    },
    onSuccess() {
      queryClient.invalidateQueries(queryKey.aiContentOptions());
    },
  };

  const generateHashtags = useMutation(
    (
      values: Omit<
        Parameters<typeof AIContentApi.generateHashtags>[0],
        'plan_type'
      >,
    ) =>
      AIContentApi.generateHashtags({
        ...values,
        plan_type: accountPlanType,
      }),
    mutationOptions,
  );

  const generateImages = useMutation(
    (
      values: Omit<
        Parameters<typeof AIContentApi.generateImages>[0],
        'plan_type'
      >,
    ) =>
      AIContentApi.generateImages({
        ...values,
        plan_type: accountPlanType,
      }),
    mutationOptions,
  );

  const generateText = useMutation(
    (
      values: Omit<
        Parameters<typeof AIContentApi.generateTexts>[0],
        'plan_type'
      >,
    ) =>
      AIContentApi.generateTexts({
        ...values,
        plan_type: accountPlanType,
      }),
    mutationOptions,
  );

  const enhanceText = useMutation(
    (
      values: Omit<Parameters<typeof AIContentApi.enhanceText>[0], 'plan_type'>,
    ) =>
      AIContentApi.enhanceText({
        ...values,
        plan_type: accountPlanType,
      }),
    mutationOptions,
  );

  return {
    generateHashtags,
    generateImages,
    generateText,
    enhanceText,
    options,
  };
}

export default useAiContentQueries;
