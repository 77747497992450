import { useEditor } from '@tiptap/react';
import { useEffect, useRef } from 'react';
import { Editor } from '@tiptap/core';
import _debounce from 'lodash.debounce';
import { Page } from 'types/Page';
import { TextEditorFeatures } from 'app/modules/textEditor/constants/textEditorFeature';
import TextEditorConvertor from 'app/modules/textEditor/utils/textEditorConvertor';
import { getTextEditorExtensions } from 'app/modules/textEditor/utils/getTextEditorExtenstions';
import { useFileAttachments } from 'app/modules/textEditor/hooks/useFileAttachments';
import { MentionableUser } from 'types/User';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import useForceRender from 'utils/hooks/useForceRender';

export type UseTextEditorProps = {
  placeholder: string;
  valueType: 'html' | 'raw';
  features: TextEditorFeatures;
  initialValue?: string;
  disabled?: boolean;
  page?: Pick<Page, 'id' | 'type'>;
  mentionableUsers?: MentionableUser[];
  onInit?(editor: Editor): void;
  debouncedChangeWait?: number;
  onDebouncedChange?(value: string): void;
  editorClassName?: string;
};

export function useTextEditor(props: UseTextEditorProps) {
  const {
    initialValue,
    onInit,
    onDebouncedChange,
    disabled,
    valueType,
    features,
    placeholder,
    debouncedChangeWait = 500,
  } = props;
  const forceRender = useForceRender();
  const aiContent = useTextEditorAiContent();
  const editor = useEditor({
    extensions: getTextEditorExtensions({
      ...props,
      setRefineTextSelectionVisible: aiContent.setRefineTextSelectionVisible,
    }),
    onBeforeCreate({ editor }) {
      editor.options.content =
        valueType === 'raw'
          ? TextEditorConvertor.textToHtml(initialValue, {
              mentionableUsers: props.mentionableUsers,
            })
          : initialValue ?? '';
    },
    onCreate: ({ editor }) => onInit?.(editor),
    onTransaction: ({ editor }) => notifyTextChanged.current(editor),
    editable: !disabled,
    editorProps: {
      attributes: {
        class: props.editorClassName ?? '',
      },
    },
  });
  const fileAttachmentStorage = useFileAttachments({ features, editor });

  useEffect(() => {
    if (editor) {
      editor.storage.props = props;
    }
  });

  useEffect(() => {
    if (editor) {
      editor.setEditable(!disabled);
    }
  }, [disabled, editor]);

  useEffect(() => {
    if (placeholder && editor) {
      const placeholderExtension = editor?.extensionManager.extensions.find(
        (extension) => extension.name === 'placeholder',
      );

      if (placeholderExtension?.options) {
        placeholderExtension.options.placeholder = placeholder;
        editor.chain().run();
      }
    }
  }, [placeholder, editor]);

  const notifyTextChanged = useRef<(editor: Editor) => void>(
    _debounce((editor: Editor) => {
      if (editor) {
        forceRender();
        onDebouncedChange?.(
          valueType === 'raw' ? editor.getText() : editor.getHTML(),
        );
      }
    }, debouncedChangeWait),
  );

  return {
    editor,
    fileAttachmentStorage,
    aiContent,
  };
}
