import { FC } from 'react';
import { STATUS } from '@kontentino/kontentino-constants/Posts';
import { Icon, mergeTailwindClasses } from '@kontentino/ui';
import { faCircleCheck as faCircleCheckRegular } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { faCircleXmark as faCircleXmarkRegular } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faClock as faClockRegular } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faClockTwelve as faClockTwelveRegular } from '@fortawesome/pro-regular-svg-icons/faClockTwelve';
import { faLockKeyhole as faLockKeyholeRegular } from '@fortawesome/pro-regular-svg-icons/faLockKeyhole';
import { faReplyClock as faReplyClockRegular } from '@fortawesome/pro-regular-svg-icons/faReplyClock';
import { faShare as faShareRegular } from '@fortawesome/pro-regular-svg-icons/faShare';
import { faSparkles as faSparklesRegular } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { faSquareUpRight as faSquareUpRightRegular } from '@fortawesome/pro-regular-svg-icons/faSquareUpRight';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faClockTwelve } from '@fortawesome/pro-solid-svg-icons/faClockTwelve';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons/faLockKeyhole';
import { faReplyClock } from '@fortawesome/pro-solid-svg-icons/faReplyClock';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons/faSparkles';
import { faSquareUpRight } from '@fortawesome/pro-solid-svg-icons/faSquareUpRight';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';

type Props = {
  status: number;
  className?: string;
  variant?: 'regular' | 'solid';
};

const SOLID_ICON_BY_STATUS: {
  [x: number]: IconDefinition;
} = {
  [STATUS.DONE]: faCircleCheck,
  [STATUS.DRAFT]: faLockKeyhole,
  [STATUS.APPROVED]: faCircleCheck,
  [STATUS.AT_CLIENT]: faClock,
  [STATUS.COMPLETED]: faSparkles,
  [STATUS.EXPORT_FAILED]: faCircleXmark,
  [STATUS.INTERNAL_APPROVED]: faCircleCheck,
  [STATUS.IS_SCHEDULING]: faClockTwelve,
  [STATUS.SCHEDULED]: faClock,
  [STATUS.POSTED]: faSquareUpRight,
  [STATUS.REJECTED]: faReplyClock,
  [STATUS.REJECTED_BY_CLIENT]: faReplyClock,
  [STATUS.WAITING_FOR]: faShare,
  [STATUS.WAITING_FOR_INTERNAL_APPROVAL]: faClock,
};

const REGULAR_ICON_BY_STATUS: {
  [x: number]: IconDefinition;
} = {
  [STATUS.DONE]: faCircleCheckRegular,
  [STATUS.DRAFT]: faLockKeyholeRegular,
  [STATUS.APPROVED]: faCircleCheckRegular,
  [STATUS.AT_CLIENT]: faClockRegular,
  [STATUS.COMPLETED]: faSparklesRegular,
  [STATUS.EXPORT_FAILED]: faCircleXmarkRegular,
  [STATUS.INTERNAL_APPROVED]: faCircleCheckRegular,
  [STATUS.IS_SCHEDULING]: faClockTwelveRegular,
  [STATUS.SCHEDULED]: faClockRegular,
  [STATUS.POSTED]: faSquareUpRightRegular,
  [STATUS.REJECTED]: faReplyClockRegular,
  [STATUS.REJECTED_BY_CLIENT]: faReplyClockRegular,
  [STATUS.WAITING_FOR]: faShareRegular,
  [STATUS.WAITING_FOR_INTERNAL_APPROVAL]: faClockRegular,
};

const WorkflowStatusIcon: FC<Props> = ({
  status,
  className,
  variant = 'solid',
}) => {
  const { postStatusVariant } = usePostsLayoutState();

  const iconByStatus =
    variant === 'solid' ? SOLID_ICON_BY_STATUS : REGULAR_ICON_BY_STATUS;

  if (!iconByStatus[status]) {
    return null;
  }

  return (
    <span
      className={mergeTailwindClasses(
        clsx(
          `tw-bg-status-${postStatusVariant}-${status}-${
            postStatusVariant === 'task' ? 'primary' : 'tertiary'
          } tw-inline-flex tw-h-4 tw-w-4 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-sm tw-text-status-${postStatusVariant}-${status}-primary`,
          className,
          {
            'tw-bg-transparent': variant === 'regular',
            '!tw-text-white': variant === 'solid',
          },
        ),
      )}
    >
      <Icon
        icon={iconByStatus[status]}
        size={variant === 'regular' ? 16 : 8}
        withoutBoundary
        iconProps={{
          fontSize: variant === 'regular' ? 12 : 8,
        }}
      />
    </span>
  );
};

export default WorkflowStatusIcon;
